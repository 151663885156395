var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
// @mui
import { Dialog, DialogTitle, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
// ----------------------------------------------------------------------
export default function Login() {
    var _a = useState(false), openForm = _a[0], setOpenForm = _a[1];
    var handleOpenModal = function () {
        setOpenForm(true);
    };
    var handleCloseModal = function () {
        setOpenForm(false);
    };
    return (_jsxs(LoginLayout, { children: [_jsx(Stack, __assign({ spacing: 2, sx: { mb: 5, position: 'relative' } }, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: "Sign in to \u0645\u0642\u0631\u0627\u062A\u0646\u0627" })) })), _jsx(LoadingButton, __assign({ fullWidth: true, color: "inherit", size: "large", type: "submit", variant: "contained", onClick: function () { return handleOpenModal(); }, sx: {
                    bgcolor: 'text.primary',
                    color: function (theme) { return (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'); },
                    '&:hover': {
                        bgcolor: 'text.primary',
                        color: function (theme) { return (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'); },
                    },
                    mt: 4
                } }, { children: "Login with takiacademy" })), _jsxs(Dialog, __assign({ fullWidth: true, maxWidth: "xs", open: openForm, onClose: handleCloseModal }, { children: [_jsx(DialogTitle, { children: "Login" }), _jsx(AuthLoginForm, {})] }))] }));
}
